import React from 'react';
import Articles from 'components/shared/Articles';
import { H1, H2, Wrapper } from 'styles/page';

function SingleTag({ data, pageContext }) {
  const { posts, tagName } = pageContext;

  return (
    <Wrapper>
      <H1>Posts about</H1>
      <H2>{`${tagName}`}</H2>
      <div>
        {posts.length && (
          <Articles
            posts={posts}
            pageContext={pageContext}
            showHeader={false}
            showPagination={false}
            showRecentPosts={false}
          />
        )}
      </div>
    </Wrapper>
  );
}

export default SingleTag;
