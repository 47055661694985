import styled from 'styled-components';

export const H1 = styled.h1`
  font-size: 2rem;
  font-weight: normal;
  letter-spacing: 0.36rem;
  text-transform: uppercase;
`;

export const H2 = styled.h2`
  font-family: 'Oswald', Arial, Verdana, 'Helvetica Neue', Helvetica, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.36rem;
  font-weight: 400;
  font-size: 4rem !important;
`;

export const Wrapper = styled.div`
  margin-top: 7rem;
  text-align: center;
`;
